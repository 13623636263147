import _ from 'lodash';
window._ = _;
// window.Popper = require('popper.js').default;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

// try {
//     window.$ = window.jQuery = require('jquery');

//     require('bootstrap');
// } catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.axios.interceptors.response.use(null, function (error) {
    if (!window.axios.isCancel(error)) {
        if (error.response.status === 403 && error.response.config.method != 'get') {
            window.alertMsg.push({ type: 'error', message: 'You\'re not allowed to do this.' });
        }
        if (error.response.status === 401) {
            window.location = '/login';
        }
    }

    return Promise.reject(error);
});

window.axios.interceptors.request.use(function (config) {
    if (!config.cancelToken && window.VueApp && window.VueApp.$data) {
        let cancelSource = window.axios.CancelToken.source();
        config.cancelToken = cancelSource.token;
        window.VueApp.$data.cancelTokens.push(cancelSource);
    }

    if (window.VueApp && window.VueApp.userObj) {
        config.headers.nodata = window.VueApp.userObj.data ? false : true;
    } else {
        config.headers.nodata = true;
    }


    return config;
}, function (error) {
    return Promise.reject(error);
});
